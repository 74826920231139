@import "src/ui/colors";
@import "src/ui/variables";

.layout {
  min-height: 100vh;
}

.footer {
  text-align: center;
  height: $footer-height;
  margin-left: $sider-width-collapsed;
  background-color: $white-color;
  transition: all 0.2s;

  &--collapsed {
    margin-left: $sider-width;
  }
}
