@import "src/ui/colors";
@import "src/ui/variables";

// TODO: Create custom buttons styles

.button {
  &.noPadding {
    padding-left: 0;
    padding-right: 0;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  height: 44px !important;
}

.primary {
  color: $white-color;
  &:disabled {
    color: $white-color;
    background: rgb(128, 130, 159) !important;
  }

  border: none;
  background: #000646;
  &:hover,
  &:active,
  &:focus {
    color: $white-color;
    background: #000646 !important;
  }
}
