@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap);
body{margin:0;font-family:"Poppins", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace}

.PageHeader_pageHeader__HEv5k{position:fixed;width:100%;background-color:#fff;transition:all 0.2s ease;z-index:1;height:70px}

.Wrapper_wrapper__3RpXK+.Wrapper_wrapper__3RpXK .Wrapper_content__3hHW9{padding-top:0}.Wrapper_content__3hHW9{padding:16px;padding-top:88px}

.Inputs_inputBasic__378VD{border-radius:4px}.Inputs_inputNumber__1KU8p{padding-top:6px;min-height:52px;border-radius:16px}.Inputs_inputPassword__3VJzc{border-radius:4px}.Inputs_inputTextarea__JIK5A{border-radius:4px;min-height:100px !important}.Inputs_inputSelect__2EHIV .ant-select-selector{border-radius:4px !important}

.Table_table__2sQY0 .ant-table-cell{max-width:100px !important;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}

.Search_search__1daQT{max-width:360px}

.Button_button__1vnDj{display:flex;justify-content:center;align-items:center;font-size:16px;font-style:normal;font-weight:600;line-height:20px;letter-spacing:0px;text-align:center;height:44px !important}.Button_button__1vnDj.Button_noPadding__3712H{padding-left:0;padding-right:0}.Button_primary__Celrb{color:#fff;border:none;background:#000646}.Button_primary__Celrb:disabled{color:#fff;background:#80829f !important}.Button_primary__Celrb:hover,.Button_primary__Celrb:active,.Button_primary__Celrb:focus{color:#fff;background:#000646 !important}

.ManageInfluencerDrawer_itemDataWrapper___sp_2{display:flex !important;align-items:flex-end !important}.ManageInfluencerDrawer_itemDataWrapper___sp_2 .ManageInfluencerDrawer_influencerImage__hlSj9{border-radius:50%}.ManageInfluencerDrawer_itemDataWrapper___sp_2 .ManageInfluencerDrawer_influencerName__3U0nh{margin-left:16px}.ManageInfluencerDrawer_dropStatusHeader__3oBSs{width:100%;display:inline-flex;align-items:center;justify-content:space-between;padding-right:23px}

.DropStatus_itemDataWrapper__1ymuL{display:flex !important;align-items:flex-end !important}

.ListItem_primary__1snxP{padding:15px !important;margin:24px 0;border:none !important;border-radius:16px;background:#fff}

.Loading_container__2EDT_{width:100%;height:100vh;display:flex;justify-content:center;align-items:center}

.InfluencerDetailDrawer_itemDataWrapper__2kAsm{display:flex !important;align-items:flex-end !important}

.PaymentsDrawer_drawer__okqY6 .PaymentsDrawer_content__2Yqzq{display:block}.PaymentsDrawer_drawer__okqY6 .PaymentsDrawer_spinner__3sCMS{display:none}.PaymentsDrawer_drawer__okqY6.PaymentsDrawer_drawerLoading__2h_SH .ant-drawer-body{display:flex;justify-content:center;align-items:center}.PaymentsDrawer_drawer__okqY6.PaymentsDrawer_drawerLoading__2h_SH .PaymentsDrawer_content__2Yqzq{display:none}.PaymentsDrawer_drawer__okqY6.PaymentsDrawer_drawerLoading__2h_SH .PaymentsDrawer_spinner__3sCMS{display:block}

.CardsHoldingDrawer_drawer__2U3Yw .CardsHoldingDrawer_content__giCaE{display:block}.CardsHoldingDrawer_drawer__2U3Yw .CardsHoldingDrawer_spinner__YOenD{display:none}.CardsHoldingDrawer_drawer__2U3Yw.CardsHoldingDrawer_drawerLoading__3nSa4 .ant-drawer-body{display:flex;justify-content:center;align-items:center}.CardsHoldingDrawer_drawer__2U3Yw.CardsHoldingDrawer_drawerLoading__3nSa4 .CardsHoldingDrawer_content__giCaE{display:none}.CardsHoldingDrawer_drawer__2U3Yw.CardsHoldingDrawer_drawerLoading__3nSa4 .CardsHoldingDrawer_spinner__YOenD{display:block}

.OrdersHistoryDrawer_drawer__1nLOk .OrdersHistoryDrawer_content__1-av6{display:block}.OrdersHistoryDrawer_drawer__1nLOk .OrdersHistoryDrawer_spinner__1pPY8{display:none}.OrdersHistoryDrawer_drawer__1nLOk.OrdersHistoryDrawer_drawerLoading__3mF83 .ant-drawer-body{display:flex;justify-content:center;align-items:center}.OrdersHistoryDrawer_drawer__1nLOk.OrdersHistoryDrawer_drawerLoading__3mF83 .OrdersHistoryDrawer_content__1-av6{display:none}.OrdersHistoryDrawer_drawer__1nLOk.OrdersHistoryDrawer_drawerLoading__3mF83 .OrdersHistoryDrawer_spinner__1pPY8{display:block}

.TransactionsDrawer_drawer__2TMWc .TransactionsDrawer_content__huq7Q{display:block}.TransactionsDrawer_drawer__2TMWc .TransactionsDrawer_spinner__2h4dK{display:none}.TransactionsDrawer_drawer__2TMWc.TransactionsDrawer_drawerLoading__17hTo .ant-drawer-body{display:flex;justify-content:center;align-items:center}.TransactionsDrawer_drawer__2TMWc.TransactionsDrawer_drawerLoading__17hTo .TransactionsDrawer_content__huq7Q{display:none}.TransactionsDrawer_drawer__2TMWc.TransactionsDrawer_drawerLoading__17hTo .TransactionsDrawer_spinner__2h4dK{display:block}

.WithdrawRequestDrawer_drawer__2n0z7 .WithdrawRequestDrawer_content__1vQoL{display:block}.WithdrawRequestDrawer_drawer__2n0z7 .WithdrawRequestDrawer_spinner__3KFx-{display:none}.WithdrawRequestDrawer_drawer__2n0z7.WithdrawRequestDrawer_drawerLoading__1SNKC .ant-drawer-body{display:flex;justify-content:center;align-items:center}.WithdrawRequestDrawer_drawer__2n0z7.WithdrawRequestDrawer_drawerLoading__1SNKC .WithdrawRequestDrawer_content__1vQoL{display:none}.WithdrawRequestDrawer_drawer__2n0z7.WithdrawRequestDrawer_drawerLoading__1SNKC .WithdrawRequestDrawer_spinner__3KFx-{display:block}

.AuthLayout_wrapper__1Orzb{background-color:#f6f7f8;position:fixed;top:0;left:0;height:100%;width:100%}.AuthLayout_container__7hjFk{max-width:330px}.AuthLayout_logoWrapper__1_EGO{display:flex;justify-content:center;margin-bottom:32px}.AuthLayout_logo__1MD7P{width:150px}

.Layout_links__258MS{display:flex;justify-content:space-between;margin-bottom:16px}

.Layout_layout___nc-0{min-height:100vh}.Layout_footer__1AOx9{text-align:center;height:70px;margin-left:80px;background-color:#fff;transition:all 0.2s}.Layout_footer--collapsed__1DXsr{margin-left:200px}

.Header_header__3KZEG{position:fixed;top:1px;right:0;left:0;background-color:#fff;display:flex;justify-content:flex-end;align-items:center;box-shadow:none;margin-bottom:1px;z-index:9;padding:0 16px;margin-left:80px;transition:all 0.2s}.Header_header--collapsed__2HBwj{margin-left:200px}.Header_header__3KZEG .Header_headerUserName__lppDB{cursor:pointer;display:flex;align-items:center;font-size:14px;font-weight:700;text-align:center;margin-right:24px}.Header_header__3KZEG .Header_headerUserName__lppDB :hover{opacity:0.7}.Header_header__3KZEG .Header_headerUserName__lppDB .Header_userIcon__3ztgd{margin-right:4px;position:relative;top:-1px}.Header_header__3KZEG .Header_signOutButton__21blJ{display:flex;align-items:center;cursor:pointer}.Header_header__3KZEG .Header_signOutButton__21blJ .Header_signOutButtonText__x2_WE{color:#595959;padding:0 4px;font-size:14px;font-weight:500}.Header_header__3KZEG .Header_signOutButton__21blJ .Header_signOutButtonIcon__3Kxl5{color:#595959;font-size:12px}

.Content_contentWrapper__NgiaP{margin-top:calc(calc(1px + 64px));margin-left:calc(200px);transition:all 0.2s ease;min-height:calc( 100vh - ( calc(1px + 64px) + 88px + 70px + 16px ))}.Content_collapsedWidth__29h63{margin-left:calc(80px)}

.Sider_logo__vqT4g{max-width:140px;margin:16px 20px 0px 20px}.Sider_sider__2qRPY{overflow:auto;height:100vh;position:fixed;left:0;z-index:2;background-color:white;border:none}.Sider_menu__2TjMb{border:none;margin-top:16px}

